import React from "react"
import "./ContentContact.scss"
import Banner from "../../molecules/Banner/Banner"
import FormContact from "../FormContact/FormContact"
import { graphql, useStaticQuery } from "gatsby"

const ContentContact = () => {

  const { allContentfulContenidoContactanos } = useStaticQuery(
    graphql`
        query Contact_Query {
            allContentfulContenidoContactanos {
                nodes {
                    metaTitulo
                    metaDescription {
                        metaDescription
                    }
                    imagenBanner {
                        description                        
                        fluid(maxWidth: 1980) {
                            aspectRatio
                            base64
                            sizes
                            src
                            srcSet
                        }
                    }
                    tituloBanner
                    descripcionPrincipal {
                        raw
                    }
                    descripcionContactanos {
                        raw
                    }
                    mapa {
                        lat
                        lon
                    }
                }
            }
        }

    `
  )

  const contentInfo = allContentfulContenidoContactanos.nodes[0]


  return (
    <>
      <Banner title={contentInfo.tituloBanner} image={contentInfo.imagenBanner} type="fluid" />
      <FormContact content={contentInfo} />
    </>
  )
}

export default ContentContact
