import React from "react"
import Layout from "../components/organisms/Layout/Layout"
import SEO from "../components/seo"
import "../styles/index.scss"
import ContentContact from "../components/organisms/ContentContact/ContentContact"
import { graphql } from "gatsby"

const Contactanos = (props) => {
  const { allContentfulContenidoContactanos } = props.data

  return (
    <Layout>
      <SEO
        title={allContentfulContenidoContactanos.nodes[0].metaTitulo}
        description={allContentfulContenidoContactanos.nodes[0].metaDescription.metaDescription}
      />
      <ContentContact />
    </Layout>
  )
}

export const query = graphql`
    query Meta_Query_Contact{
        allContentfulContenidoContactanos {
            nodes {
                metaTitulo
                metaDescription {
                    metaDescription
                }
            }
        }
    }
`

export default Contactanos
