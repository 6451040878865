import React, { useState } from "react"
import "./FormContact.scss"

import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Modal from "../../molecules/Modal/Modal"
import Loading from "../../atoms/loading/loading"


const FormContact = ({ content }) => {
  const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  const [firstNames, setFirstNames] = useState("")
  const [lastNames, setLastNames] = useState("")
  const [telphone, setTelphone] = useState("")
  const [mail, setMail] = useState("")
  const [message, setMessage] = useState("")

  const [firstNamesError, setFirstNamesError] = useState(false)
  const [lastNamesError, setLastNamesError] = useState(false)
  const [telphoneError, setTelphoneError] = useState(false)
  const [mailError, setMailError] = useState(false)
  const [messageError, setMessageError] = useState(false)

  const [modalSendOk, setModalSendOk] = useState(false)
  const [modalSendError, setModalSendError] = useState(false)

  const [sending, setSending] = useState(false)


  const handleSubmit = e => {
    e.preventDefault()

    if (!haveErrors()) {
      sendEmail()
    }
  }


  const sendEmail = () => {
    setSending(true)
    let params = {
      user_id: process.env.EMAILJS_USER_ID,
      service_id: process.env.EMAILJS_SERVICE_ID,
      template_id: process.env.EMAILJS_TEMPLATE_CONTACT,
      template_params: {
        "names": firstNames,
        "lastNames": lastNames,
        "mail": mail,
        "tel": telphone,
        "message": message
      }
    }

    let headers = {
      "Content-type": "application/json"
    }

    let options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(params)
    }

    fetch("https://api.emailjs.com/api/v1.0/email/send", options)
      .then((res) => {
        if (res.ok) {
          setModalSendOk(true)
          setFirstNames("")
          setLastNames("")
          setTelphone("")
          setMail("")
          setMessage("")
        } else {
          console.log("error")
          setModalSendError(true)
        }
      })
      .catch((error) => {
        console.log("Oops... " + error)
        setModalSendError(true)
      })
      .finally(() => {
        setSending(false)
      })

  }

  const haveErrors = () => {


    if (firstNames === "" || firstNames === " ") {
      setFirstNamesError("Este campo es requerido")
    } else {
      setFirstNamesError(false)
    }


    if (lastNames === "" || lastNames === " ") {
      setLastNamesError("Este campo es requerido")
    } else {
      setLastNamesError(false)
    }

    if (telphone === "" || telphone === " ") {
      setTelphoneError("Este campo es requerido")
    } else {
      setTelphoneError(false)
    }

    if (mail === "") {
      setMailError("Este campo es requerido")
    } else if (!(emailRegex.test(mail))) {
      setMailError("Correo inválido")
    } else {
      setMailError(false)
    }

    if (message === "" || message === " ") {
      setMessageError("Este campo es requerido")
    } else {
      setMessageError(false)
    }

    return (firstNames === "" || firstNames === " ") ||
      (lastNames === "" || lastNames === " ") ||
      (telphone === "" || telphone === " ") ||
      (mail === "") || (!(emailRegex.test(mail))) ||
      (message === "" || message === " ")
  }


  const options1 = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => <h2 className="form__info-title">{children}</h2>
    }
  }

  const options2 = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node, children) => <h3 className="form__direction-title">{children}</h3>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="form__direction-description">{children}</p>
    }
  }


  return (
    <>
      {modalSendOk && (
        <Modal modalForm={true} setShowModal={setModalSendOk}>
          <h3 className="modal__title">Muchas Gracias Por Comunicarse Con Nosotros</h3>
          <p className="modal__paragraph">¡Muchas gracias por comunicarese con nosotros! Sus preguntas y opinion son muy
            importantes para nosotros y
            responderemos tan pronto como nos sea posible.</p>
        </Modal>
      )}

      {modalSendError && (
        <Modal modalForm={true} setShowModal={setModalSendError}>
          <h3 className="modal__title error">¡Error Al Enviar El Formulario!</h3>
          <p className="modal__paragraph error">Hemos tenido problemas al enviar el formulario, por favor inténtalo
            nuevamente.</p>
        </Modal>
      )}

      <section className="form">
        <div className="form__wrapper">
          <div className="form__info">
            {documentToReactComponents(JSON.parse(content.descripcionPrincipal.raw), options1)}
          </div>
          <div className="form__content">

            <form className="form__form" onSubmit={handleSubmit}>

              <div className={`form__input ${firstNamesError ? "error" : ""}`}>
                <label>Nombres</label>
                <input
                  type="text"
                  name="names"
                  value={firstNames}
                  onChange={e => setFirstNames(
                    e.target.value
                      .replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ ]/g, "")
                      .replace(/ {2}/g, " ")
                      .slice(0, 40)
                  )}
                />
                {firstNamesError && <p className="form__input-error">{firstNamesError}</p>}
              </div>
              <div className={`form__input ${lastNamesError ? "error" : ""}`}>
                <label>Apellidos</label>
                <input
                  type="text"
                  name="lastNames"
                  value={lastNames}
                  onChange={e => setLastNames(
                    e.target.value
                      .replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ ]/g, "")
                      .replace(/ {2}/g, " ")
                      .slice(0, 40)
                  )}
                />
                {lastNamesError && <p className="form__input-error">{lastNamesError}</p>}
              </div>
              <div className={`form__input ${mailError ? "error" : ""}`}>
                <label>Correo</label>
                <input type="email"
                       name="mail"
                       value={mail}
                       onChange={e => setMail(
                         e.target.value
                           .replace(/ /g, "")
                           .slice(0, 80)
                       )}
                />
                {mailError && <p className="form__input-error">{mailError}</p>}

              </div>
              <div className={`form__input ${telphoneError ? "error" : ""}`}>
                <label>Teléfono</label>
                <input
                  type="tel"
                  name="tel"
                  value={telphone}
                  onChange={e => setTelphone(
                    e.target.value
                      .replace(/\D/, "")
                      .slice(0, 10)
                  )}
                />
                {telphoneError && <p className="form__input-error">{telphoneError}</p>}
              </div>
              <div className={`form__input textarea ${messageError ? "error" : ""}`}>
                <label>Mensaje</label>
                <textarea
                  name="message"
                  value={message}
                  onChange={e => setMessage(
                    e.target.value
                      .replace(/[^a-zA-ZáéíóúÁÉÍÓÚ1234567890,. ]/g, "")
                      .replace(/ {2}/g, " ")
                      .slice(0, 1200)
                  )}
                > </textarea>
                {messageError && <p className="form__input-error">{messageError}</p>}

              </div>
              <div className="form__button">
                {sending ? (
                  <Loading />
                ) : (
                  <button>Enviar</button>

                )}

              </div>


            </form>
            <div className="form__direction">
              {documentToReactComponents(JSON.parse(content.descripcionContactanos.raw), options2)}

              <div className="form__direction-map">
                <iframe
                  src={`https://maps.google.com/maps?q=${content.mapa.lat},${content.mapa.lon}&hl=es;z=14&output=embed`}
                  allowFullScreen=""
                  loading="lazy">
                </iframe>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>

  )
}

export default FormContact
