import React, { useEffect } from "react"
import "./Modal.scss"

const Modal = ({ children, showModal, setShowModal, modalForm =false, actions }) => {


  useEffect(() => {
    bodyOverFlowBlock("open")
  })

  const bodyOverFlowBlock = (action) => {
    const body = document.getElementsByTagName("body")[0]
    if (action === "open") {
      body.classList.add("body-blook")
    } else {
      body.classList.remove("body-blook")
      if(actions){
        actions()
      }
    }
  }


  return (

    <div className="modal">
      <div className={`modal__content ${modalForm ? 'formResp' : ''}`}>
        <span className="modal__close"
              onClick={() => {
                bodyOverFlowBlock("close")
                setShowModal(false)
              }}
        >
<i className="fas fa-times"></i>
        </span>
        {children}
      </div>
    </div>
  )
}

export default Modal
