import React from "react"
import "./Banner.scss"
import Img from "gatsby-image"

const Banner = ({ title, image, type }) => {
  return (
    <section className="banner">
      <div className="banner__image">

        {type === "fluid" ? (
          <Img
            className="img-fluid"
            loading="lazy"
            itemProp="image"
            fluid={image.fluid}
            alt={image.description}
          />
        ) : (
          <img src={image} />

        )}
      </div>
      <h1 className="banner__txt">{title}</h1>
    </section>
  )
}

export default Banner
